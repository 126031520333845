import {Container, Nav} from 'react-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import {Outlet, useNavigate} from 'react-router-dom';

const Layout = () : JSX.Element => {
    const navigate = useNavigate();
    return (
        <>
            <header>
                <Navbar bg="light" expand="lg">
                    <Container>
                        <Navbar.Collapse id="basic-navbar-nav" role="menubar">
                            <Nav
                                className="me-auto"
                                onSelect={k => {
                                    if (k) {
                                        navigate(k);
                                    }
                                }}
                            >
                                <Nav.Link eventKey="/software">Software</Nav.Link>
                                <Nav.Link eventKey="/devices">Devices</Nav.Link>
                                <Nav.Link eventKey="/notes">Notes</Nav.Link>
                                <Nav.Link eventKey="/users">Users</Nav.Link>
                                <Nav.Link eventKey="/macversions">Mac Versions</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <main>
                <Outlet/>
            </main>
        </>
    );
};

export default Layout;
